
import insta from './FooterImages/Instagram.png'
import Linked from './FooterImages/LinkedIn.png'
import Behance from './FooterImages/Behance.png'
export default function Footer(){
    return(
        <div className="footer-container">        
        <footer className="footer">
            <div class="footer-txt">
                <h3>Narendra Bengeri</h3>
                <h3>Pixncrafts.com</h3>
                <h3>Pixnarendradesigns@gmail.com</h3>
            </div>
            <ul className="footer-links">
                <a href="https://www.instagram.com/ig_narendra_25?igsh=bDBvdDYwczBiYXl6&utm_source=qr" target='_blank' rel='noreferrer'>
                    <img src={insta} style={{width:'50px'}} alt='instagram_Link'></img>
                </a>
                <a href="https://www.linkedin.com/in/narendra-bengeri-13170816b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target='_blank' rel='noreferrer'>
                    <img src={Linked} style={{width:'50px'}} alt='LinkedIn Link'></img>
                </a>
                <a href="https://www.behance.net/narendrabengeri" target='_blank' rel='noreferrer'>
                    <img src={Behance} style={{width:'50px'}} alt='Behnace Link'></img>
                </a>
            </ul>
        </footer>
        </div>

    )
}