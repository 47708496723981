import React from "react"
import logo2 from "./My_Pic.png"
import src1 from "./Scripts/abt-script"
import { Helmet } from "react-helmet"

export default function About(){
    return(
        <>
        <Helmet>
         <title>About | Pixncrafts.com</title>
         <meta name='description' content='Welcome to my portfolio. Find out more about me and my intrests.'></meta>
      </Helmet>
        <div className="abt-container">
        <img src={logo2} className="img_2" alt="PersonalPic"></img>
            <div class="para-div">
            <h1 className="abt-head">Little Bit About Me :)</h1>
                <p className="abt-para">
                    Hi, I'm Narendra Bengeri, a versatile blend of a front-end developer, blender enthusiast and graphic designer.
                <br/>
                    I have around 2 years of experience working as a web developer and designer.
                    <br/>
                    <br/>
                    My journey into the realms of design and development started with a passion for creativity and a keen eye for detail. Along the way, I've had the privilege of exploring various facets of design, from creating decent visual compositions to bringing them to life through code.
                    <br/>
                    <br/>
                    I craft intutive UI or experiment with the latest design trends, my goal is always to deliver solutions that resonate with users and leave a lasting impression!.
                    <br/>
                    <br/>
                    I'm excited to continue pushing the boundaries of design and development, always striving to create experiences that captivate, inspire, and make a difference. Let's embark on this journey together and bring your ideas to life!.
                    <br/>
                    <br/>
                    If you have any roles that you think would be a perfect match for me, Kindly drop a mail to:- <u><b>Pixnarendradesigns@gmail.com</b></u>


                </p>
            </div>
        </div>
        <script src={src1}></script>
        </>

        
    )
}