import React from 'react';
import { Link } from 'react-router-dom';

import Logo1 from './Logo1.png'
import Logo2 from './Anim1.gif'


export default function Header(){
    return(
      
        <nav className='navbar'>
          <div className="nav-img"><img src={Logo2} className='img_1' alt='Pixncrafts_Logo'></img></div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/anim">Animations</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    )
}