

import galleryData from "./GalleryImages/galleryImages"
import { Helmet } from "react-helmet"





export default function Gallery(){
    
    return(
        <div className="gallery-main">
            <Helmet>
                <title>Gallery | Pixncrafts.com</title>
                <meta name='description' content='Welcome to my portfolio. Explore different images in this section and drop a mail to me if you like these designs.'></meta>
            </Helmet>
            <div className="gallery-heading">
                <h1>Welcome to gallery!</h1>
                <p>Featuring innovative 3D designs.<br/>
                Imagination and Creativity in action.</p>
            </div>
            <div className="gallery">
                {galleryData.map((img)=>(
                        <div class="gallery-item"><img key={img.id} src={img.url} alt={img.desciption}></img></div>
                ))}
                
            </div>
            <div className="gallery-heading">
            <h2>Like these designs?.<br/>
                Drop a mail to: <u><b>Pixnarendradesigns@gmail.com</b></u> for further queries.</h2>
            </div>
            
           

        </div>

        
    )
}