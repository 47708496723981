import React from "react"
import logo3 from './Form-img.png'
import {useState} from "react"
import { Helmet } from "react-helmet"


export default function Contact(){
    const[formData, setFormData] = useState({
        fName: '',
        lName: '',
        contact: '',
        email: '',
        message: ''
    })

    
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
      };


      const handleSubmit = async (event) => {
        event.preventDefault();
    
        const sendEmail = async (formData) => {
          const response = await fetch('https://formspree.io/f/xdoqjver/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
          });
    
          if (!response.ok) {
            throw new Error('Failed to send email');
          }
        };
    
        try {
          await sendEmail(formData); // Call sendEmail here with formData
          setFormData({
            fName: '',
            lName: '',
            contact: '',
            email: '',
            message: '',
          });
          alert('Your message has been sent successfully!'); // Optional success message
        } catch (error) {
          console.error('Error sending email:', error);
          alert('An error occurred. Please try again later.'); // Optional error message
        }
      };
      
     
   

    return(
      <>
          <Helmet>
                <title>Contact | Pixncrafts.com</title>
                <meta name='description' content='Welcome to my portfolio. Contact me for further queries. Drop a mail to Pixnarendradesigns@gmail.com'></meta>
            </Helmet>
        <form className="form-container" onSubmit={(event) => handleSubmit(event)}>
        <img src={logo3} className="form-img" alt="MultipleImages"></img>
        <div className="form-control"> 
          <h1>Let's get in touch!</h1>
          <p className="form-para">Please, provide the details..</p>
        <label htmlFor="f-name">First Name: </label>
        <input id="f-name" type="text" name="fName"  value={formData.fName} onChange={handleChange} required></input>
        <br></br>
        <label>Last Name: </label>
        <input type="text" name="lName"  value={formData.lName} onChange={handleChange}required></input>
        <br></br>
        <label>Contact No: </label>
        <input type="text" name="contact"  value={formData.contact} onChange={handleChange} required></input>
        <br></br>
        <label>Email Addr: </label>
        <input type="text" name="email"  value={formData.email} onChange={handleChange} required></input>
        <br></br>
        
        <label>Your Message : </label>
        <input type="text" name="message"  value={formData.message} onChange={handleChange} required></input>

        <br></br>
        <div class="btn-div"><button type="submit" className="btn-submit">Submit</button></div>
        
        </div>
        
        </form>
        </>
    )
}