const galleryData = [
    {
        id:1,
        url: "https://i.postimg.cc/wj0cCM6K/Ghost-With-Heart-pillow.jpg",
        desciption:"ghost-loving"
    },
    {
        id:2,
        url: "https://i.postimg.cc/1t2MP45H/Donuts-1-2-P.jpg",
        desciption:"Donuts on the table"
    },
    {
        id:3,
        url: "https://i.postimg.cc/dtMnx7fQ/Final-Realism-Kitchen-PS.jpg",
        desciption:"Kitchen Realistic"
    },
    {
        id:4,
        url: "https://i.postimg.cc/5tPn7nhD/Girl-Sculpt.jpg",
        desciption:"Girl Sclupt"
    },
    {
        id:5,
        url: "https://i.postimg.cc/76fXpSDh/Iso-1.jpg",
        desciption:"Iso design"
    },
    {
        id:6,
        url: "https://i.postimg.cc/05KcnGcH/Junkie-P-1.jpg",
        desciption:"Junk Foods"
    },
    {
        id:6,
        url: "https://i.postimg.cc/8PhwQp8R/Low-Poly-1.jpg",
        desciption:"Low Poly"
    },
    {
        id:8,
        url: "https://i.postimg.cc/7hz9LD5y/Tiki-1-Edited.jpg",
        desciption:"Tiki"
    },
    {
        id:9,
        url: "https://i.postimg.cc/nhp2nyZ6/Wine-1.jpg",
        desciption:"Wine on table"
    },
    {
        id:10,
        url: "https://i.postimg.cc/Jnf3vgPY/3-Fishes.png",
        desciption:"Three Fishes"
    },
    {
        id:11,
        url: "https://i.postimg.cc/7Pzn0HZF/Cyberpunk-Isometric.png",
        desciption:"CyberPunk Design"
    },
    {
        id:12,
        url: "https://i.postimg.cc/JnDcTmcB/Hot-Dog-Cartoon-FStyle.jpg",
        desciption:"Hot Dog"
    },
    {
        id:13,
        url: "https://i.postimg.cc/J0j3LqFr/Isometric-kitchen-Composited.png",
        desciption:"Kitchen Iso"
    },
    {
        id:14,
        url: "https://i.postimg.cc/pXtYBw9p/Real-interior-Final-2.jpg",
        desciption:"Interior design"
    },
    {
        id:15,
        url: "https://i.postimg.cc/qMXcD1nQ/Villa-Scene-Finished.jpg",
        desciption:"Villa Scene"
    },
    {
        id:16,
        url: "https://i.postimg.cc/k5VbCsV0/Bust-Edited.jpg",
        desciption:"Chracter Bust"
    },
    {
        id:17,
        url: "https://i.postimg.cc/RFKJ60LG/Car-Image-edited.jpg",
        desciption:"Car vintage"
    },
    {
        id:18,
        url: "https://i.postimg.cc/6QDGpZny/JET-1-E.jpg",
        desciption:"Jet Flying in air"
    },
    {
        id:19,
        url: "https://i.postimg.cc/9ML5Bdbf/Hacker-room.jpg",
        desciption:"Hacker Room Iso"
    },
    {
        id:20,
        url: "https://i.postimg.cc/8cs8X7Xm/Haunted-Car-Frame.jpg",
        desciption:"Haunted Scene"
    },
    {
        id:21,
        url: "https://i.postimg.cc/wvqYJfhP/Monsterrr.jpg",
        desciption:"One-eyed Monster"
    },
    {
        id:22,
        url: "https://i.postimg.cc/L8YL8dmv/IMG-0587.jpg",
        desciption:"AI in action"
    },
    {
        id:23,
        url: "https://i.postimg.cc/zv2Hq7M9/Iso-Hut.jpg",
        desciption:"Isometric Hut"
    },
    {
        id:24,
        url: "https://i.postimg.cc/tRBxdRT2/Low-poly-Art.png",
        desciption:"Low Poly Design"
    },
    {
        id:25,
        url: "https://i.postimg.cc/52dNLDws/Ancient-Ruins.png",
        desciption:"Ancient Ruins Iso"
    },
    {
        id:26,
        url: "https://i.postimg.cc/9fz7Nvny/Car-Quick-Sand-scene.jpg",
        desciption:"QuickSand"
    },
]

export default galleryData