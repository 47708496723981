
import './App.css';
import About from "./About"
import Anim from "./Anim"
import Contact from "./Contact"
import Gallery from "./Gallery"
import Home from "./Home"
import Header from './Header';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import Footer from './Footer';
import Helmet from 'react-helmet'


function App() {
  return (
    <div>
      <Helmet>
          <title>Pixncrafts.com</title>
          <meta name='description' content='Welcome to Pixncrafts.com, My Name is Narendra Bengeri and you will find my designs here. Please do have a look at them and contact me if you find them interesting!'></meta>
      </Helmet>
    <Header/>
    
    <Routes>
          <Route exact path='/' element={<Home/>}></Route>
          <Route path="/gallery" element={<Gallery/>}></Route>
          <Route path='/anim' element={<Anim/>}></Route>
          <Route path='/about' element={<About/>}></Route>
          <Route path='/contact' element={<Contact/>}></Route>
    </Routes>
    <Footer/>
    </div>
    
  );
}

export default App;
