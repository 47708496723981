import { useEffect, useState,useRef } from 'react'
import div1 from './HomeDivImages/Kitchen.png'
import div2 from './HomeDivImages/Tiki.png'
import div3 from './HomeDivImages/Cafe.png'
import div4 from './HomeDivImages/Whiskey.png'
import div5 from './HomeDivImages/LowPolyIsland.png'
import div6 from './HomeDivImages/LoveGhost.png'
import div7 from './HomeDivImages/AbandonedLand.png'
import div8 from './HomeDivImages/AI.png'
import broom from './HomeDivImages/Bedroom.png'
import isoH from './HomeDivImages/IsoHouse.png'
import hotDog from './HomeDivImages/HotDog.png'
import Car from './HomeDivImages/CarVintage.png'
import Jet from './HomeDivImages/JetFighter.png'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

export default function Home(){
//    const [scrollTop, setScrollTop] = useState(0);
const imagesRef = useRef([]);

const [showDetail, setShowDetails] = useState(false)

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: Array.from(Array(101).keys(), i => i / 100) // Generate an array [0, 0.01, ..., 1.0]
    };
    let debounceTimeout;
    const pendingUpdates = new Map();
 
    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          pendingUpdates.set(entry.target, entry.intersectionRatio);
        } else {
          entry.target.style.transform = 'scale(0.2)';
          pendingUpdates.delete(entry.target);
        }
      });

      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        requestAnimationFrame(updateTransforms);
      }, 15);
    };

    const updateTransforms = () => {
      pendingUpdates.forEach((intersectionRatio, target) => {
        target.style.transform = `scale(${0.1 + intersectionRatio * 0.9})`;
      });
      pendingUpdates.clear();
    };
   
    const observer = new IntersectionObserver(observerCallback, observerOptions);

    imagesRef.current.forEach(image => {
      if (image) observer.observe(image);
    });

    return () => {
      clearTimeout(debounceTimeout);
      imagesRef.current.forEach(image => {
        if (image) observer.unobserve(image);
      });
    };
  }, []);

  const showDetails = ()=>{
      setShowDetails(!showDetail)
  }
   

//    useEffect(() => {
//       const handleScroll = () => {
//         setScrollTop(window.scrollY);
//       };
  
//       window.addEventListener('scroll', handleScroll);
  
//       return () => {
//         window.removeEventListener('scroll', handleScroll);
//       };
//     }, []);

   

//     const opacity1 = Math.max(0, Math.min(1, (window.innerHeight - scrollTop) / window.innerHeight));
//   const opacity2 = Math.max(0, Math.min(1, scrollTop / window.innerHeight));

 
   return(
      <div className='home'>
      <Helmet>
         <title>Home | Pixncrafts.com</title>
         <meta name='description' content='Welcome to my portfolio. Explore the designs that I made using blender3D and Adobe PS'></meta>
      </Helmet>
      <div class="home-heading">
         <h1>Pix-'N'-Crafts Renders</h1>
         <p>Welcome to my Portfolio!.<br/>
         Explore different variations of designs that I made using Blender3D and Adobe PS.</p>
      </div>
         <div className="image-container">
         <div className='image-wrapper1'>
               <img src={hotDog} ref={el => (imagesRef.current[0]=el)} className='image' ></img>
               <div class="txt-w-1">
                  <h1>Happy HotDog!</h1>
                  <p>The Happy Hotdog, the wiener that's always a winner! Sporting a smile brighter than a summer's day,<br/> this frankfurter is ready to bring joy to your taste buds.</p>
               </div>
            </div>
            <div className='image-wrapper1'>
               <img src={broom} ref={el => (imagesRef.current[1]=el)} className='image'></img>
               <div class="txt-w-2">
                  <h1>80's Bedroom!</h1>
                  <p>A nostalgic 80s bedroom with bold colors, retro furniture, neon accents, vintage posters, and iconic pop culture <br></br> decor.</p>
               </div>
            </div>
            <div className='image-wrapper1'>
               <img src={isoH} ref={el => (imagesRef.current[2]=el)} className='image'></img>
               <div class="txt-w-3">
                  <h1>LowPoly Island!</h1>
                  <p>Behold Isometric Island, the angular escape that's a hit with polygons everywhere!<br/> Featuring sharp-edged cliffs and perfectly placed palm trees, this low-poly utopia is a feast for the eyes.</p>
               </div>
            </div>
            <div className='image-wrapper1'>
               <img src={Car} ref={el => (imagesRef.current[3]=el)} className='image' onClick={showDetails}></img>
               <div class="txt-w-4">
                  <h1>Timeless Machine!</h1>
                  <p>Feast your eyes on this BMW vintage beauty, where classic charm meets timeless engineering. <br/>With curves smoother than a jazz tune and a grill that grins, this car is a blast from the past.</p>
               </div>
            </div>
            <div className='image-wrapper1'>
               <img src={Jet} ref={el => (imagesRef.current[4]=el)} className='image' onClick={showDetails}></img>
               <div class="txt-w-5">
                  <h1>Tactical Fighter!</h1>
                  <p>Say hello to the F15 jet, the ultimate sky conqueror. <br/>With a design that is more aerodynamic than your wildest dreams and power to match,<br/> it is the superhero of the skies.</p>
               </div>
            </div>
         {/* {images.map((_, index) => (
        <div key={index} className="image-wrapper">
          <img
            ref={el => (imagesRef.current[index] = el)}
            src={_}
            alt={`Image ${index + 1}`}
            className="image"
          />
        </div>
      ))} */}
         </div>



         
         {/* <div className='home-container'>
            <div class="cont-1">
               <div className="rectangle" style={{ right: -scrollTop / 8, opacity: opacity1 }}>
               <img src={div1} alt="Bedroom Design" className="image"/>
                  <hr className='div-hr1'></hr>
                        </div>
                        
                        <div className="rectangle" style={{ left: -scrollTop / 8, opacity: opacity1, top: '30vh' }}>
               <img src={div2} alt="Tiki" className="image" />
               <hr className='div-hr2'></hr>
                        </div>
                        
                        <div className="rectangle" style={{ right: -scrollTop / 8, opacity: opacity1, top: '60vh' }}>
               <img src={div3} alt="Cafe Restaurant" className="image" />
               <hr className='div-hr3'></hr>
                        </div>
                        
            </div>
         <div class="cont-2">
            <div className="rectangle" style={{ left: (-scrollTop / 20)/2, opacity: opacity2, top: '90vh' }}>
               <img src={div4} alt="HotDog Dancing" className="image" />
               <hr className='div-hr4'></hr>
            </div>
            <div className="rectangle" style={{ right: (-scrollTop / 20)/2, opacity: opacity2, top: '120vh' }}>
               <img src={div6} alt="Cute Love" className="image" />
               <hr className='div-hr5'></hr>
            </div>
            <div className="rectangle" style={{ left: (-scrollTop / 20)/4, opacity: opacity2, top: '150vh' }}>
               <img src={div5} alt="LowPoly Island" className="image" />
               <hr className='div-hr6'></hr>
            </div>
         </div>
         <div className="rectangle" style={{ right: (-scrollTop / 20)/2, opacity: opacity2, top: '180vh' }}>
            <img src={div7} alt="Abandoned Land" className="image" />
            <hr className='div-hr7'></hr>
         </div>
         <div className="rectangle" style={{ left: (-scrollTop / 20)/2, opacity: opacity2, top: '210vh' }}>
            <img src={div8} alt="AI and Code" className="image" />
            <hr className='div-hr8'></hr>
         </div>
         
        
            
         </div> */}
         <p className='home-para-end'>Creativity has no bounds and limits!.<br/>
         Explore more in the Gallery page :)
         </p>
      </div>
   )
    
}
