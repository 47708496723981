import React from "react"
import vid1 from './Anim-1.mp4'
import vid2 from './Anim-2.mp4'
import { Helmet } from "react-helmet"



export default function Anim(){
    return(
        
        <div className="anim-container">
            <Helmet>
                <title>Animations | Pixncrafts.com</title>
                <meta name='description' content='Welcome to my portfolio. Explore the animations done by me using blender and Adobe AE.'></meta>
            </Helmet>
            <h1 className="anim-header">Wondering about Animations?. No worries, I have got it!</h1>
        <div class="videos">
            <video autoPlay loop className="vid">
                <source src={vid1} type="video/mp4"></source>
            </video>
            <video autoPlay loop className="vid">
                <source src={vid2} type="video/mp4"></source>
            </video>
        </div>
            <p className="anim-para">I have more than 2 years of experience in Animation, rigging, compositing <br></br>using tools like Blender and
            Adobe After Effects. <br/> Let it be a jet firing a missile or a man chasing the car..<br/> Even animation has no limits!.
            <br/> If you have any experience in animation or rigging, Please do reach out to me as I prefer more to work as a team!.</p>
        </div>
    )
}